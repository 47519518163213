<template>
<div class="page-container">
     <p class="main-title" >{{$route.query.main_title}}<a @click="reload"><i class="el-icon-refresh"></i></a></p>   
    <div class="page-desc">
    该数据为成员向应用发送的消息记录
    </div>
     <el-table 
            :data="tableData" 
            border
            style="width:100%">
             <el-table-column    
                prop="FromUserName"
                label="成员">
            </el-table-column>
             <el-table-column    
                prop="msg_type_text"
                label="消息类型">
            </el-table-column>
             <el-table-column    
                prop="content"
                label="消息内容">
                <template slot-scope="scope">
                    <label v-if="scope.row.MsgType == 'text'">{{scope.row.content.content}}</label>
                   <div v-else-if="scope.row.MsgType == 'image'">
                        <el-image style="width:80px;height:80px;" :src="$imgDomain+scope.row.content.url"></el-image>
                    </div>
                     <label v-else-if="scope.row.MsgType == 'location'">{{scope.row.content.Label}}</label>
                     <video v-else-if="scope.row.MsgType == 'video'" style="width:120px;height:150;" :src="$imgDomain+scope.row.content.VideoUrl"></video>
                     <div v-else-if="scope.row.MsgType == 'link' ">
                        <label>标题:{{scope.row.content.Title}}</label>
                        <label>描述:{{scope.row.content.Description}}</label>
                        <label>调整url:{{scope.row.content.Url}}</label>
                        <el-image :src="scope.row.content.PicUrl" ></el-image>
                     </div>
                </template>
            </el-table-column>
             <el-table-column    
                prop="create_time"
                label="创建时间">
            </el-table-column>
    </el-table>
    <pagination-view :table-data-total='total' @currPage='getCurrPage' @pageSize='getPageSize'></pagination-view> 
</div>
</template>

<script>
import BenzAMRRecorder  from "benz-amr-recorder"
export default {
    inject:['reload'],
      props: {
        audioUrl: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            tableData:[],
            currentPage:1,
            pagesize:10,
            total:0,
            amr:null,
            audioUrl: {
                type: String,
                required: true,
            },
        }
    },
    created() {
        this.getData()
    },
    mounted() {

    },
    methods:{
        getData(){
           this.$api.post('SysConfig/appMsg',{page:this.currentPage,limit:this.pagesize},res=>{
               if(res.code == 200){
                   this.tableData =  res.data.list
                   this.total = res.data.count
               }
           })
        },
        //播放语音
        openRecording(url){
            
        },
        getCurrPage(val){   //获取当前页
            this.currentPage =  val
            this.getData()
        },
        getPageSize(val){   //获取每页条数
            this.pagesize =  val
            this.getData()
        },
    },
}
</script>
<style scoped>

</style>